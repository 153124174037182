import React, { useState,useEffect } from 'react'
import { PrinterOutlined,EditOutlined,CloseOutlined } from '@ant-design/icons';
import { Card, Table, Button, InputNumber, Divider, Row, Col, message,Spin } from 'antd';
import { NumToStr, usaFormatNumber } from '../../../utils/helper'; 
import Footer from '../../../components/layout-components/modal/Footer';
import moment from 'moment'
import API from '../../../api'
import { connect } from 'react-redux';
import {getInYardList,getClosedOutList} from '../../../actions/myYardAction';
import {usePosition} from '../../../utils/usePosition';
import InputField from '../../../components/data-display/table/InputField';
import PopOverEmailBtn from '../../../components/popOverEmail';
const { Column } = Table;


 const Estimate =(props)=> {
	const [invoice,setinvoice]=useState(null)
	const [estimate,setEstimate]=useState(0)
	const [invoiceData,setInvoiceData]=useState(null)
	const {yardTicket,yardEstUpdate,mailEstimate}=API
	const [print,setPrint]=useState(false)
	const [inputDisable,setInputDisable]=useState(true)
	const [btnDisable, setBtnDisable] = useState(false);
	const [error,setError]=useState(false);
	const [loading,setLoading]=useState(true)
	const { latitude, longitude } = usePosition();

	useEffect(()=>{
		setLoading(true)
		if(props.visible===true){
		yardTicket(props.data?.ticket_id||props.data?.ticket_details?.ticket_id).then((res)=>{
			const _data=res.data.data
			setLoading(false)
				setinvoice(_data)
			const invoiceArr=[]
			_data.sub_tickets.map((item,i)=>{
				invoiceArr.push({key:i+1,service:item.services.param_description})
			})
			setInvoiceData(invoiceArr)
				setEstimate(parseFloat(_data?.estimate_price)) 
			}).catch((err)=>{
				setLoading(false)
			})
		}else{
			setInputDisable(true)
		}
	},[props.visible])

	const onSubmit=()=>{
		setBtnDisable(true);
		const data={
			"ticket_id":props.data?.ticket_id||props.data?.ticket_details?.ticket_id,
		"price": estimate,
		"coordinates": [
			latitude??	0.00,
			longitude??	0.00
		  ]
	  }
		setError(false)
	  yardEstUpdate(data).then((res)=>{
		  if(res.data.success===true){
			props.setVisible(false)
			props.getInYardList(props.inYardPage,'');
			props.getClosedOutList(props.closedOutPage,'')
			setBtnDisable(false);
			message.success(res.data.message)
		  }else{
			setBtnDisable(false);
			message.error(res.data.message)
		  }
	
	  }).catch((err)=>{
		setBtnDisable(false);

		})}
		 
		
const printDiv=(divName)=> {
	// props.setVisible(false)
	setInputDisable(true)
	setTimeout(()=>{
		setPrint(true)
	},1000)
}
useEffect(()=>{
	if(print){
		window.print();
			setPrint(false)
			setInputDisable(true)
			// props.setVisible(true)
	}
},[print])

	const total=()=> {
		let total = 0;
		invoiceData.forEach((elm) => {
			total += elm.price;
		})
		return total
	}
	const handleInput=(e)=>{
		if(e){
			setError(false)
		}else{
			setError(true)
		}
		setEstimate(e)
	
	}

	const SendMail = (mail) =>{
		const body = {
			to:mail,
			from:'info@washtrax.com',
			subject:`ESTIMATE FROM ${invoice?.client_details.company_name}`,
			total_amt:estimate?.toFixed(2),
			sub_tickets:invoice?.sub_tickets,
			ticket_details:{
			 po_no:invoice?.po_details?.po_no??'NA',
			 ticket_no:invoice?.ticket_ref_id,
			 equipment_type:invoice?.asset_details.equipment_type_details.param_description,
			 equipment_no:invoice?.asset_details.equipment_no
			},
			customer_details:{
				zipcode:invoice?.customer_details?.billing_zipcode,
				billing_state:invoice?.customer_details?.billing_state,
			billing_city:invoice?.customer_details?.billing_city,
			billing_address:invoice?.customer_details?.billing_address,
			name:invoice?.customer_details?.name
			},
			client_details:{
				zipcode:invoice?.client_details?.billing_zipcode,
			billing_state:invoice?.client_details?.billing_state,
			 billing_city:invoice?.client_details?.billing_city,
			billing_address:invoice?.client_details?.billing_address,
			first_name:invoice?.client_details?.billing_first_name,
			last_name:invoice?.client_details?.billing_last_name,
			company_name:invoice?.client_details?.company_name,
			billing_phone:invoice?.client_details?.billing_phone,
			billing_email:invoice?.client_details?.billing_email
			},
			coordinates: [
				0.00,
				0.00
				],
			ticket_id:invoice?.ticket_id
		}
		mailEstimate(body).then(res =>{
			message.success("Mail Sent")
		}).catch(e =>{
				message.error('Something went wrong')
		})
	}
		return (
			<Footer
      setVisible={(e) => props.setVisible(e)}
      visible={props.visible}
      Button={props.Button}
	  buttonCustomWidth={126}
	  buttonCustomColor="#6BBAA7"
      title={props.title}
      submit={
		  <div className="d-flex d-print-none" style={{justifyContent:'space-between'}}>
			  <div>
			  <Button style={{marginRight:10}} type="primary" danger onClick={()=>props.setVisible(false)}>
							<span className="ml-1">Cancel</span>
						</Button>
						</div>
<div className="d-flex">

					<div className="text-right" style={{marginRight:10}}>
						<Button type="primary" style={{background:'orange',borderColor:'orange'}} onClick={()=>printDiv('printableArea')}>
							<PrinterOutlined  type="printer" />
							<span className="ml-1">Print</span>
						</Button>
					</div>
					<PopOverEmailBtn title="Email" Email={(data) => SendMail(data)}/>
					<Button
			type="primary"
			disabled={error||btnDisable}
			htmlType="submit"
			onClick={onSubmit}
          >
            Submit
          </Button>

		  </div>
		  </div>
         
      }
    >
            {!loading?<div className="container" style={{width:'100%'}} >
				<Card id="printableArea">
				<div className="page-footer d-flex">
  <div className="text-center hide-footer" >
  <p>
							<small>
							<div className="text-center">For any questions please call {usaFormatNumber(invoice?.client_details.billing_phone)} with the Wash Ticket number details.
							<br/>
							Thank you for your business!</div>
							</small>
							<div className="text-center" >
						<p>Electronically produced on <span style={{color:'rgb(239 198 73)'}}>WashTRAX<sup>TM</sup></span> and no signature required. All rights reserved. © 2021   </p>
					</div> 
						</p>
					</div> 
  </div>
  <table style={{width:'100%'}}>

<thead>
  <tr>
	<td>
	  {/* <!--place holder for the fixed-position header--> */}
	  {/* <div className="page-header-space"></div> */}
	</td>
  </tr>
</thead>

<tbody>
  <tr>
	<td>

	  {/* <!--*** CONTENT GOES HERE ***--> */}
	  <div className="page">

					<div className="d-flex" style={{justifyContent:'space-between'}}>
						<div>
							<img src="/img/logo.png" alt=""/>
							<address>
								<p>
									<span className="font-weight-semibold text-dark font-size-md">{invoice?.client_details.company_name}</span><br />
									<span>{invoice?.client_details.billing_address}</span><br />
									<span>{invoice?.client_details.billing_city}, {invoice?.client_details.billing_state} {invoice?.client_details.billing_zipcode}</span><br />
									<abbr className="text-dark" title="Phone">Phone:</abbr>
									<span>{usaFormatNumber(invoice?.client_details.billing_phone)}</span>
								</p>
							</address>
						</div>
						<div className="mt-3 text-right">
							<h2 className="mb-0 font-weight-semibold">Estimate</h2>
							<p className="mb-0 font-weight-semibold">{moment(Date.now()).format('MM/DD/YYYY')}</p>
							<address>
								<p>
									<span className="font-weight-semibold text-dark font-size-md">{invoice?.customer_details.name}</span><br />
									<span>{invoice?.customer_details.billing_address} </span><br />
									<span>{invoice?.customer_details.billing_city}, {invoice?.customer_details.billing_state} {invoice?.customer_details.zipcode}</span>
								</p>
							</address>
						</div>
					</div>
					<Row gutter={24} className="invoice-fields">
						<Col span={12}>
							<span><b>Equipment #:</b> {invoice?.asset_details.equipment_no}</span>
						</Col>
						<Col span={12}>
							<span><b>TICKET NO.:</b> {invoice?.ticket_ref_id}</span>
						</Col>

						<Col span={12}>
							<span><b>Equipment Type:</b> {invoice?.asset_details?.equipment_type_details?.param_description}</span>
						</Col>
						<Col span={12}>
							<span><b>PO Number #:</b> {invoice?.po_details?.po_no??'NA'}</span>
						</Col>
					</Row>

					<div className="mt-4">
						{invoiceData&&invoiceData.length!==0?<Table dataSource={invoiceData} pagination={false} className="mb-5">
							<Column title="NO." dataIndex="key" key="key"/>
							<Column title="SERVICES" dataIndex="service" key="product"/>
							
						</Table>:<div style={{height:250}}></div>}
						<div className="d-flex justify-content-end align-items-center">
							{/* <div className="text-right "> */}
								
								<h2 className="font-weight-semibold mt-3">
									<span className="mr-1">GRAND TOTAL : {inputDisable&&<span>${NumToStr(estimate.toFixed(2),0)}</span>}</span>
								</h2>
								{!inputDisable&&<div>
									{/* <InputField style={{width:'180px'}} className="ant-input" value={estimate} type="number" change={(e)=>handleInput(e)} /> */}
								
				<InputNumber
                  style={{ width:120, fontSize: 18 }}
                  min={0}
				  precision={2}
				  value={estimate}
				  onChange={(e) => handleInput(e)}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
								{error&&<div class="ant-form-item-explain ant-form-item-explain-error"><div role="alert">Please Input Grand Total</div></div>}		
								</div>}
							{/* </div> */}
							{inputDisable&&<div className="edit-btn d-print-none" onClick={()=>setInputDisable(false)}><EditOutlined style={{color:'#48A4F4'}} /></div>}
							{!inputDisable&&<div className="edit-btn d-print-none" onClick={()=>setInputDisable(true)}><CloseOutlined style={{color:'#48A4F4'}} /></div>}
						</div>
						<Divider/>
						
					</div>
					</div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td>
          {/* <!--place holder for the fixed-position footer--> */}
          <div className="page-footer-space mt-5">
		  <div className="text-center d-print-none" >
		  <p>
							<small>
							<div className="text-center">For any questions please call {usaFormatNumber(invoice?.client_details.billing_phone)} with the Wash Ticket number details.
							<br/>
							Thank you for your business!</div>
							</small>
							<div className="text-center" >
						<p>Electronically produced on <span style={{color:'rgb(239 198 73)'}}>WashTRAX<sup>TM</sup></span> and no signature required. All rights reserved. © 2021   </p>
					</div> 
						</p>
					</div> 
		  </div>
        </td>
      </tr>
    </tfoot>

  </table>
				</Card>
			</div>:<div className="spinner-main"><Spin /></div>}
			<style>{`
			.invoice-fields{
			border-top: 1px solid;
			padding: 14px 0px 8px 0px;
			border-bottom: 1px solid;}
			.invoice-input{

			}
			.edit-btn{
				font-size: 21px;
				margin-left:10px;
				cursor:pointer;
			}
			.hide-footer{
				display:none;
			}
			
				   
					  @page {
						margin: 20mm
					  }
					  
					  @media print {
						.page-header, .page-header-space {
							height: 10px;
						  }
						  .hide-footer{
							display:block;
						}
						  
						  .page-footer, .page-footer-space {
							height: 65px;
						  
						  }
						  
						  .page-footer {
							position: fixed;
							bottom: 0;
							width: 100%;
						  }
						  
						  .page-header {
							position: fixed;
							top: 0mm;
							left:0;
							right:0;
							width: 100%;
						  }
						  
						  .page {
							page-break-after: always;
						  }
						  
						 thead {display: table-header-group;} 
						 tfoot {display: table-footer-group;}
						 
						 button {display: none;}
						 
						 body {margin: 0;}
					  }
			`		
			}</style>
			</Footer>
        )
}
const mapStateToProps = (state) => ({
	inYardPage:state.myYard.inYardPage,
	closedOutPage:state.myYard.closedOutPage
  });
  

export default connect(mapStateToProps, {getInYardList,getClosedOutList })(
	Estimate
  );
  